export default [
  {
    path: '/feeds',
    name: 'feeds-index',
    component: () => import('@/views/feeds/export/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.feeds',
      breadcrumb: [
        {
          text: 'administration.navigation.feeds',
          active: true,
        },
      ],
      resource: 'feeds',
      action: 'index',
    },
  },
  {
    path: '/feeds/create',
    name: 'feeds-store',
    component: () => import('@/views/feeds/export/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.feeds',
      breadcrumb: [
        {
          text: 'administration.navigation.feeds',
          to: { name: 'feeds-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'feeds',
      action: 'store',
    },
  },
  {
    path: '/feeds/edit/:id',
    name: 'feeds-update',
    component: () => import('@/views/feeds/export/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.feeds',
      breadcrumb: [
        {
          text: 'administration.navigation.feeds',
          to: { name: 'feeds-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'feeds',
      action: 'update',
    },
  },
  {
    path: '/imports',
    name: 'imports-index',
    component: () => import('@/views/feeds/import/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.imports',
      breadcrumb: [
        {
          text: 'administration.navigation.imports',
          active: true,
        },
      ],
      resource: 'feeds',
      action: 'index',
    },
  },
  {
    path: '/imports/create',
    name: 'imports-store',
    component: () => import('@/views/feeds/import/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.imports',
      breadcrumb: [
        {
          text: 'administration.navigation.imports',
          to: { name: 'imports-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'feeds',
      action: 'store',
    },
  },
  {
    path: '/imports/edit/:id',
    name: 'imports-update',
    component: () => import('@/views/feeds/import/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.imports',
      breadcrumb: [
        {
          text: 'administration.navigation.imports',
          to: { name: 'imports-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'feeds',
      action: 'update',
    },
  },
]
