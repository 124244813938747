<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          class="hidden"
        >
          <b-form-group
            :label="$t('form.maintenance_mode.label')"
            label-for="maintenance_mode"
            :state="errors && errors.maintenance_mode ? false : null"
          >
            <v-select
              id="maintenance_mode"
              v-model="data.maintenance_mode"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="maintenance_mode"
            />
            <b-form-invalid-feedback v-if="errors && errors.maintenance_mode">
              {{ errors.maintenance_mode[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Скинути системний кеш (фільтри і тп.)"
            label-for="cache"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="disabledCache"
              @click="clearCache"
            >
              {{ $t('general.run') }}
            </b-button>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Оновити клієнтський фронтенд"
            label-for="cache"
          >
            <b-button
              variant="warning"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="disabledCache"
              @click="buildClient"
            >
              {{ $t('general.run') }}
            </b-button>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Оновити адмінський фронтенд"
            label-for="cache"
          >
            <b-button
              variant="warning"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="disabledCache"
              @click="buildAdmin"
            >
              {{ $t('general.run') }}
            </b-button>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Примусово перегенерувати фіди"
            label-for="cache"
          >
            <b-button
              variant="info"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="disabledCache"
              @click="generateFeeds"
            >
              {{ $t('general.run') }}
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="$ability.can('update', 'settings') && 1 === 2"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'
import StatusMixin from '@/mixins/StatusMixin'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'

export default {
  components: {
    BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, vSelect,
  },
  mixins: [StatusMixin, SweetAlertMixin],
  data() {
    return {
      data: null,
      disabledCache: false,
      disabledClient: false,
      disabledAdmin: false,
      disabledFeeds: false,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http('/api/administration/settings', { params: { group: 'general' } }).then(response => {
      const { data: { maintenance_mode } } = response

      this.data = {
        maintenance_mode,
      }
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/administration/settings/general', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    clearCache() {
      this.confirm(() => {
        this.disabledCache = true
        setTimeout(() => {
          this.disabledCache = false
        }, 60000)
        this.$http.post('/api/administration/cache')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: 'Запустити скидання системного кешу?',
        text: 'Скидання триває 1-2 хв. Оновлює кеш фільтрів, конфігурації та чистить чергу невдалих фонових завдань.',
      })
    },
    buildClient() {
      this.confirm(() => {
        this.disabledClient = true
        setTimeout(() => {
          this.disabledClient = false
        }, 60000)
        this.$http.post('/api/administration/build_client')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: 'Запустити білдинг клієнтської частини?',
        text: 'Скидання триває 2-5 хв у фоновому режимі (декілька разів нажимати не треба), оновлює скрипти, стилі, переклади, меню. Рекомендовано запускати не частіше 2 разів на добу (не підряд) у період найменшої активності користувачів.',
      })
    },
    buildAdmin() {
      this.confirm(() => {
        this.disabledAdmin = true
        setTimeout(() => {
          this.disabledAdmin = false
        }, 60000)
        this.$http.post('/api/administration/build_admin')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: 'Запустити білдинг адмінської частини?',
        text: 'Скидання триває 2-5 хв у потоковому режимі (адмінка буде частково недоступна), оновлює скрипти та переклади. У цей час не проводити дії з адмінкою через ризик втрати вашої роботи.',
      })
    },
    generateFeeds() {
      this.confirm(() => {
        this.disabledFeeds = true
        setTimeout(() => {
          this.disabledFeeds = false
        }, 60000)
        this.$http.post('/api/administration/generate_feeds')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: 'Запустити генерацію?',
        text: 'Генерація триває 10-20 хв у фоновому режимі (декілька разів нажимати не треба) і оновлює фіди та сайтмап. Запускати лише за потреби, так як генерація фідів поглинає багато ресурсів і зловжівання нею призбеде до перегрузу сервера.',
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
