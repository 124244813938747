<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('administration.products.tabs.general.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.class.label')"
                    label-for="class"
                  >
                    <b-form-input
                      :id="`class`"
                      v-model="item.class"
                      :state="errors && errors.class ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.class">
                      {{ errors.class[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="type"
                      @input="changeType"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="item.type === 'banner'"
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.banner_type.label')"
                    label-for="banner_type"
                    :state="errors && errors.banner_type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.banner_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="bannerTypeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="banner_type"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.banner_type">
                      {{ errors.banner_type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="item.type === 'product'"
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.product_type.label')"
                    label-for="product_type"
                    :state="errors && errors.product_type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.product_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productTypeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="product_type"
                      @input="changeProductType"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.product_type">
                      {{ errors.product_type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="item.type === 'post'"
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.product_type.label')"
                    label-for="post_type"
                    :state="errors && errors.post_type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.post_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="postTypeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="post_type"
                      @input="changePostType"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.post_type">
                      {{ errors.post_type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="item.type === 'banner' || item.type === 'product'"
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.href.label')"
                        :label-for="'link.' + language.code"
                      >
                        <b-form-input
                          :id="'link.' + language.code"
                          v-model="item.link[language.code]"
                          :state="errors && errors['link.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['link.' + language.code]">
                          {{ errors['link.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="item.type === 'html' || item.type === 'seo'"
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <quill-editor
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :options="editorOptions"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="item.type === 'seo'"
                      cols="12"
                      md="4"
                    >
                      <b-form-group :label="$t('form.avatar.label')">
                        <!-- media -->
                        <b-media no-body>
                          <b-media-aside>
                            <b-link>
                              <b-img
                                ref="previewEl"
                                rounded
                                :src="$options.filters.mediaUrl(item, 'seo_avatar', '150x150')"
                                height="80"
                              />
                            </b-link>
                            <!--/ avatar -->
                          </b-media-aside>

                          <b-media-body class="mt-75 ml-75">
                            <InitFileManager
                              field="seo_avatar"
                              type="extension_seo_avatar"
                              :multiple="false"
                              @fm-selected="selectedSeoAvatar"
                            />

                            <!-- reset -->
                            <b-button
                              v-if="item.seo_avatar"
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              variant="outline-secondary"
                              size="sm"
                              class="mb-75 mr-75"
                              @click="item.seo_avatar = null"
                            >
                              {{ $t('general.reset') }}
                            </b-button>
                            <!--/ reset -->

                          </b-media-body>
                        </b-media>
                        <!--/ media -->
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab
              v-if="item.type === 'banner'"
              :title="$t('administration.extensions.tabs.banners.label')"
            >
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>

                  <div class="repeater-form">
                    <b-row
                      v-for="(banner, index) in item.banners"
                      :id="'banner' + index + language.code"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group :label="$t('form.background.label')">
                          <!-- media -->
                          <b-media no-body>
                            <b-media-aside>
                              <b-link>
                                <b-img
                                  ref="previewEl"
                                  rounded
                                  :src="$options.filters.mediaUrl(banner, 'background', '150x150', language.code)"
                                  height="80"
                                />
                              </b-link>
                              <!--/ avatar -->

                              <!-- reset -->
                              <b-button
                                v-if="banner.background[language.code]"
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                size="sm"
                                class="mb-75 mr-75"
                                @click="banner.background[language.code] = null"
                              >
                                {{ $t('general.reset') }}
                              </b-button>
                              <!--/ reset -->
                            </b-media-aside>

                            <b-media-body class="mt-75 ml-75">
                              <InitFileManager
                                field="background"
                                type="extension_background"
                                :multiple="false"
                                @fm-selected="selectedBackground($event, index, language.code)"
                              />
                            </b-media-body>
                          </b-media>
                          <!--/ media -->
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group :label="$t('form.background_mobile.label')">
                          <!-- media -->
                          <b-media no-body>
                            <b-media-aside>
                              <b-link>
                                <b-img
                                  ref="previewEl"
                                  rounded
                                  :src="$options.filters.mediaUrl(banner, 'background_mobile', '150x150', language.code)"
                                  height="80"
                                />
                              </b-link>
                              <!--/ avatar -->

                              <!-- reset -->
                              <b-button
                                v-if="banner.background_mobile[language.code]"
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                size="sm"
                                class="mb-75 mr-75"
                                @click="banner.background_mobile[language.code] = null"
                              >
                                {{ $t('general.reset') }}
                              </b-button>
                              <!--/ reset -->
                            </b-media-aside>

                            <b-media-body class="mt-75 ml-75">
                              <InitFileManager
                                field="background_mobile"
                                type="extension_background_mobile"
                                :multiple="false"
                                @fm-selected="selectedBackgroundMobile($event, index, language.code)"
                              />
                            </b-media-body>
                          </b-media>
                          <!--/ media -->
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.blank.label')"
                          :label-for="'banners.' + index + '.only_preview'"
                        >
                          <b-form-checkbox
                            :id="'banners.' + index + '.only_preview'"
                            v-model="banner.only_preview"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.only_preview']">
                            {{ errors['banners.' + index + '.' + '.only_preview'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'banners.' + index + '.title.' + language.code"
                        >
                          <b-form-input
                            :id="'banners.' + index + '.title.' + language.code"
                            v-model="banner.title[language.code]"
                            :state="errors && errors['banners.' + index + '.title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.title.' + language.code]">
                            {{ errors['banners.' + index + '.title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="(item.banner_type === 'one_row' || item.banner_type === 'three_row') ? $t('form.description.label') : $t('form.href.label')"
                          :label-for="'banners.' + index + '.href.' + language.code"
                        >
                          <b-form-input
                            :id="'banners.' + index + '.href.' + language.code"
                            v-model="banner.href[language.code]"
                            :state="errors && errors['banners.' + index + '.href.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.href.' + language.code]">
                            {{ errors['banners.' + index + '.href.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.position.label')"
                          :label-for="'banners.' + index + '.position'"
                        >
                          <b-form-input
                            :id="'banners.' + index + '.position'"
                            v-model="banner.position"
                            :state="errors && errors['banners.' + index + '.position'] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.position']">
                            {{ errors['banners.' + index + '.' + '.position'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                        :class="`hidden`"
                      >
                        <b-form-group
                          :label="$t('form.google_name.label')"
                          :label-for="'banners.' + index + '.google_name.' + language.code"
                        >
                          <b-form-input
                            :id="'banners.' + index + '.google_name.' + language.code"
                            v-model="banner.google_name[language.code]"
                            :state="errors && errors['banners.' + index + '.google_name.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.google_name.' + language.code]">
                            {{ errors['banners.' + index + '.google_name.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                        :class="`hidden`"
                      >
                        <b-form-group
                          :label="$t('form.google_creative.label')"
                          :label-for="'banners.' + index + '.google_creative.' + language.code"
                        >
                          <b-form-input
                            :id="'banners.' + index + '.google_creative.' + language.code"
                            v-model="banner.google_creative[language.code]"
                            :state="errors && errors['banners.' + index + '.google_creative.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.google_creative.' + language.code]">
                            {{ errors['banners.' + index + '.google_creative.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                        :class="`hidden`"
                      >
                        <b-form-group
                          :label="$t('form.google_position.label')"
                          :label-for="'banners.' + index + '.google_position'"
                        >
                          <b-form-input
                            :id="'banners.' + index + '.google_position'"
                            v-model="banner.google_position"
                            :state="errors && errors['banners.' + index + '.google_position'] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.google_position']">
                            {{ errors['banners.' + index + '.' + '.google_position'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="3"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeBanner(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addBanner"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.banners"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.banners[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.type === 'seo'"
              :title="$t('administration.extensions.tabs.faq.label')"
            >
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>

                  <div class="repeater-form">
                    <b-row
                      v-for="(faq, index) in item.faq"
                      :id="'faq' + index + language.code"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.question.label')"
                          :label-for="'faq.' + index + '.question.' + language.code"
                        >
                          <b-form-input
                            :id="'faq.' + index + '.question.' + language.code"
                            v-model="faq.question[language.code]"
                            :state="errors && errors['faq.' + index + '.question.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['faq.' + index + '.question.' + language.code]">
                            {{ errors['faq.' + index + '.question.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="24"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.answer.label')"
                          :label-for="'answer.' + language.code"
                          :state="errors && errors['answer.' + language.code] ? false : null"
                        >
                          <quill-editor
                            :id="'answer.' + language.code"
                            v-model="faq.answer[language.code]"
                            :options="editorOptions"
                            :state="errors && errors['answer.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['answer.' + language.code]">
                            {{ errors['answer.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="3"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeFaq(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addFaq"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.faq"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.faq[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.type === 'product' && item.product_type === 'manual'"
              :title="$t('administration.extensions.tabs.products.label')"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(product, index) in item.products"
                  :id="'product' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.products.label')"
                      :label-for="'products.' + index + '.id'"
                      :state="errors && errors['products.' + index + '.id'] ? false : null"
                    >
                      <v-select
                        :id="'products.' + index + '.id'"
                        v-model="product.id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="productOptions"
                        :reduce="val => val.value"
                        :input-id="'products.' + index + '.id'"
                        :searchable="true"
                        @search="searchProduct"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['products.' + index + '.id']">
                        {{ errors['products.' + index + '.id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeProduct(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addProduct"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.products"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.products[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.type === 'post' && item.post_type === 'manual'"
              :title="$t('administration.extensions.tabs.post.label')"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(post, index) in item.posts"
                  :id="'post' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.posts.label')"
                      :label-for="'posts.' + index + '.id'"
                      :state="errors && errors['posts.' + index + '.id'] ? false : null"
                    >
                      <v-select
                        :id="'posts.' + index + '.id'"
                        v-model="post.id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="postOptions"
                        :reduce="val => val.value"
                        :input-id="'posts.' + index + '.id'"
                        :searchable="true"
                        @search="searchPost"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['posts.' + index + '.id']">
                        {{ errors['posts.' + index + '.id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removePost(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addPost"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.posts"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.posts[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.type === 'categories'"
              :title="$t('administration.extensions.tabs.categories.label')"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(category, index) in item.categories"
                  :id="'category' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.categories.label')"
                      :label-for="'categories.' + index + '.id'"
                      :state="errors && errors['categories.' + index + '.id'] ? false : null"
                    >
                      <v-select
                        :id="'categories.' + index + '.id'"
                        v-model="category.id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="categoryOptions"
                        :reduce="val => val.value"
                        :input-id="'categories.' + index + '.id'"
                        :searchable="true"
                        @search="searchCategory"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['categories.' + index + '.id']">
                        {{ errors['categories.' + index + '.id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeCategory(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addCategory"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.categories"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.categories[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'extensions-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import htmlEditButton from 'quill-html-edit-button'
import EditorMixin from '@/mixins/EditorMixin'

import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormInvalidFeedback,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BLink,
  BFormFile,
  BFormTextarea,
  BFormDatepicker,
  BOverlay,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'
import StatusMixin from '@/mixins/StatusMixin'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'
import InitFileManager from '@/components/InitFileManager'

Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BImg,
    BFormFile,
    BFormTextarea,
    quillEditor,
    Treeselect,
    BFormDatepicker,
    BOverlay,
    InitFileManager,
  },
  mixins: [CategoryMixin, SweetAlertMixin, StatusMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      productOptions: [],
      categoryOptions: [],
      postOptions: [],
      typeOptions: [
        { label: this.$t('general.extensions.banner'), value: 'banner' },
        { label: this.$t('general.extensions.product'), value: 'product' },
        { label: this.$t('general.extensions.html'), value: 'html' },
        { label: this.$t('general.extensions.seo'), value: 'seo' },
        { label: this.$t('general.extensions.post'), value: 'post' },
        { label: this.$t('general.extensions.infocards'), value: 'infocards' },
        { label: this.$t('general.extensions.categories'), value: 'categories' },
      ],
      bannerTypeOptions: [
        { label: this.$t('general.banner_types.slider'), value: 'slider' },
        { label: this.$t('general.banner_types.two_row'), value: 'two_row' },
        { label: this.$t('general.banner_types.four_row'), value: 'four_row' },
        { label: this.$t('general.banner_types.grid'), value: 'grid' },
        { label: this.$t('general.banner_types.one_row'), value: 'one_row' },
        { label: this.$t('general.banner_types.three_row1'), value: 'three_row' },
      ],
      productTypeOptions: [
        { label: this.$t('general.extension_product_types.manual'), value: 'manual' },
        { label: this.$t('general.extension_product_types.sale'), value: 'sale' },
        { label: this.$t('general.extension_product_types.popular'), value: 'popular' },
        { label: this.$t('general.extension_product_types.newest'), value: 'newest' },
        { label: this.$t('general.extension_product_types.viewed'), value: 'viewed' },
      ],
      postTypeOptions: [
        { label: this.$t('general.extension_post_types.manual'), value: 'manual' },
        { label: this.$t('general.extension_post_types.random'), value: 'random' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/administration/extensions/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)

        this.productOptions = this.$options.filters.transformForVSelect(data.products, 'id', 'title')
        this.categoryOptions = this.$options.filters.transformForVSelect(data.categories, 'id', 'title')
        this.postOptions = this.$options.filters.transformForVSelect(data.posts, 'id', 'title')
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'extensions-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    selectedBackground(data, index, language) {
      this.item.banners[index].background[language] = data
    },
    selectedSeoAvatar(data) {
      this.item.seo_avatar = data
    },
    selectedBackgroundMobile(data, index, language) {
      this.item.banners[index].background_mobile[language] = data
    },
    async searchProduct(query) {
      await this.$http.get('/api/administration/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.productOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async searchCategory(query) {
      await this.$http.get('/api/administration/fullcategories', {
        params: {
          query,
          type: 'product',
        },
      })
        .then(response => {
          this.categoryOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
        })
    },
    async searchPost(query) {
      await this.$http.get('/api/administration/posts', {
        params: {
          query,
        },
      })
        .then(response => {
          this.postOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    changeType() {
      this.item.banner_type = null
      this.item.product_type = null
      this.item.post_type = null
      this.item.class = ''
      this.item.description = {}
      this.item.link = {}
      this.item.banners = []
      this.item.faq = []
      this.item.products = []
      this.item.categories = []
      this.item.posts = []
      this.item.seo_avatar = null

      _.each(this.languages, language => {
        this.item.description[language.code] = null
        this.item.link[language.code] = null
      })
    },
    changeProductType() {
      this.item.products = []
    },
    changePostType() {
      this.item.posts = []
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        type: this.item.type,
        banner_type: this.item.banner_type,
        product_type: this.item.product_type,
        post_type: this.item.post_type,
        class: this.item.class,
        banners: this.item.banners,
        faq: this.item.faq,
        products: this.item.products,
        categories: this.item.categories,
        posts: this.item.posts,
        status: this.item.status,
        description: this.item.description,
        link: this.item.link,
        seo_avatar: this.item.seo_avatar,
      }

      this.$http.put(`/api/administration/extensions/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'extensions-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    addBanner() {
      const data = {
        title: {},
        href: {},
        google_name: {},
        google_creative: {},
        google_position: 0,
        position: 0,
        only_preview: false,
        background: {},
        background_mobile: {},
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.href[language.code] = null
        data.google_name[language.code] = null
        data.google_creative[language.code] = null
        data.background[language.code] = null
        data.background_mobile[language.code] = null
      })

      this.item.banners.push(data)
    },
    removeBanner(index) {
      this.item.banners.splice(index, 1)
    },
    addFaq() {
      const data = {
        question: {},
        answer: {},
      }

      _.each(this.languages, language => {
        data.question[language.code] = null
        data.answer[language.code] = null
      })

      this.item.faq.push(data)
    },
    removeFaq(index) {
      this.item.faq.splice(index, 1)
    },
    addProduct() {
      this.item.products.push({
        id: null,
      })
    },
    removeProduct(index) {
      this.item.products.splice(index, 1)
    },
    addPost() {
      this.item.posts.push({
        id: null,
      })
    },
    removePost(index) {
      this.item.posts.splice(index, 1)
    },
    addCategory() {
      this.item.categories.push({
        id: null,
      })
    },
    removeCategory(index) {
      this.item.categories.splice(index, 1)
    },
    transformData(item) {
      const data = {
        id: item.id,
        status: item.status,
        type: item.type,
        banner_type: item.banner_type,
        product_type: item.product_type,
        post_type: item.post_type,
        class: item.class,
        banners: [],
        faq: [],
        products: item.products,
        categories: item.categories,
        posts: item.posts,
        title: {},
        description: {},
        link: {},
        seo_avatar: this.$options.filters.singleMedia(item.seoAvatar),
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.link[language.code] = translation ? translation.link : null
      })

      _.each(item.banners, (banner, index) => {
        data.banners[index] = {
          id: banner.id,
          title: {},
          href: {},
          google_name: {},
          google_creative: {},
          google_position: banner.google_position,
          position: banner.position,
          only_preview: banner.only_preview,
          background: {},
          background_mobile: {},
        }

        _.each(this.languages, language => {
          const translation = _.find(banner.translations, { locale: language.code })

          data.banners[index].title[language.code] = translation ? translation.title : null
          data.banners[index].href[language.code] = translation ? translation.href : null
          data.banners[index].google_name[language.code] = translation ? translation.google_name : null
          data.banners[index].google_creative[language.code] = translation ? translation.google_creative : null
          data.banners[index].background[language.code] = translation ? this.$options.filters.singleMedia(translation.background) : null
          data.banners[index].background_mobile[language.code] = translation ? this.$options.filters.singleMedia(translation.backgroundMobile) : null
        })
      })

      _.each(item.faq, (faq, index) => {
        data.faq[index] = {
          id: faq.id,
          question: {},
          answer: {},
        }

        _.each(this.languages, language => {
          const translation = _.find(faq.translations, { locale: language.code })

          data.faq[index].question[language.code] = translation ? translation.question : null
          data.faq[index].answer[language.code] = translation ? translation.answer : null
        })
      })

      return data
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
