<template>
  <section class="invoice-preview-wrapper">
    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <b-card-body class="invoice-padding">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <div>
                <h4 class="mb-0">
                  {{ $t('general.order') }}
                  <span class="invoice-number">#{{ invoiceData.id }}</span>
                  <small> ({{ invoiceData.created_at }})</small>
                </h4>
              </div>
              <div>
                <div class="invoice-date-wrapper">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    block
                    @click="printInvoice"
                  >
                    {{ $t('general.print') }}
                  </b-button>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>

        <b-row>
          <b-col
            cols="12"
            xl="4"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body>
                <h6 class="mb-2">
                  {{ $t('general.buyer') }}:
                </h6>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <label for="user_firstmane">{{ $t('orders.user_firstmane') }}</label>
                    <b-form-input
                      :id="`user_firstmane`"
                      v-model="invoiceData.user.first_name"
                      class="d-inline-block"
                      :readonly="!editable"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <label for="user_lastmane">{{ $t('orders.user_lastmane') }}</label>
                    <b-form-input
                      :id="`user_lastmane`"
                      v-model="invoiceData.user.last_name"
                      class="d-inline-block"
                      :readonly="!editable"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <label for="user_phone">{{ $t('orders.user_phone') }}</label>
                    <b-form-input
                      :id="`user_phone`"
                      v-model="invoiceData.user.phone"
                      class="d-inline-block"
                      readonly
                    />
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <label for="user_email">{{ $t('orders.user_email') }}</label>
                    <b-form-input
                      :id="`user_email`"
                      v-model="invoiceData.user.email"
                      class="d-inline-block"
                      :readonly="!editable"
                    />
                  </b-col>
                  <b-col
                    v-if="editable"
                    cols="12"
                    class="mt-1"
                    :style="`color:red`"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span>{{ $t('orders.user_edit_info') }}</span>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body>
                <h6 class="mb-2">
                  {{ $t('orders.delivery') }}:
                </h6>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <label for="delivery_method">{{ $t('orders.delivery_method') }}</label>
                    <b-form-input
                      v-if="!editable"
                      :id="`delivery_method`"
                      v-model="invoiceData.deliveryMethod.title"
                      class="d-inline-block"
                      readonly
                    />
                    <v-select
                      v-else
                      :id="`delivery_method`"
                      :value="invoiceData.deliveryMethod.title"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="deliveryValues"
                      class="w-100"
                      :clearable="false"
                      @input="setDeliveryMethod($event)"
                    />
                  </b-col>
                </b-row>
                <b-row v-if="invoiceData.deliveryMethod.key === 'self_pickup'">
                  <b-col
                    cols="12"
                  >
                    <label for="delivery_shop">{{ $t('orders.shop') }}</label>
                    <b-form-input
                      v-if="!editable"
                      :id="`delivery_shop`"
                      v-model="invoiceData.shop.title"
                      class="d-inline-block"
                      readonly
                    />
                    <v-select
                      v-else
                      :id="`payment_method`"
                      :value="invoiceData.shop.title"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="shopsValues"
                      class="w-100"
                      :clearable="false"
                      @input="setShop($event)"
                    />
                  </b-col>
                </b-row>
                <b-row v-if="invoiceData.deliveryMethod.key === 'courier'">
                  <b-col
                    cols="12"
                  >
                    <label for="delivery_city">{{ $t('orders.city') }}</label>
                    <b-form-input
                      v-if="!editable"
                      :id="`delivery_city`"
                      v-model="invoiceData.city.title"
                      class="d-inline-block"
                      readonly
                    />
                    <v-select
                      v-else
                      :id="`delivery_city`"
                      :value="invoiceData.city.title"
                      :options="citiesValues"
                      class="w-100"
                      :clearable="false"
                      @input="setCity($event)"
                      @search="filterCities"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <label for="delivery_street">{{ $t('orders.street') }}</label>
                    <b-form-input
                      :id="`delivery_street`"
                      v-model="invoiceData.address.street"
                      class="d-inline-block"
                      :readonly="!editable"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <label for="delivery_number">{{ $t('orders.number') }}</label>
                    <b-form-input
                      :id="`delivery_number`"
                      v-model="invoiceData.address.number"
                      class="d-inline-block"
                      :readonly="!editable"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <label for="delivery_flat">{{ $t('orders.flat') }}</label>
                    <b-form-input
                      :id="`delivery_flat`"
                      v-model="invoiceData.address.flat"
                      class="d-inline-block"
                      :readonly="!editable"
                    />
                  </b-col>
                </b-row>
                <b-row v-if="invoiceData.deliveryMethod.key === 'nova_poshta'">
                  <b-col
                    cols="12"
                  >
                    <label for="delivery_city">{{ $t('orders.city') }}</label>
                    <b-form-input
                      v-if="!editable"
                      :id="`delivery_city`"
                      v-model="invoiceData.city.title"
                      class="d-inline-block"
                      readonly
                    />
                    <v-select
                      v-else
                      :id="`delivery_city`"
                      :value="invoiceData.city.title"
                      :options="citiesValues"
                      class="w-100"
                      :clearable="false"
                      @input="setCity($event)"
                      @search="filterCities"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <label for="delivery_warehouse">{{ $t('orders.warehouse') }}</label>
                    <b-form-input
                      v-if="!editable"
                      :id="`delivery_warehouse`"
                      v-model="invoiceData.warehouse.title"
                      class="d-inline-block"
                      readonly
                    />
                    <v-select
                      v-else
                      :id="`delivery_warehouse`"
                      :value="invoiceData.warehouse.title"
                      :options="warehousesValues"
                      class="w-100"
                      :clearable="false"
                      @input="setWarehouse($event)"
                      @search="filterWarehouses"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body>
                <h6 class="mb-2">
                  {{ $t('orders.payment') }}:
                </h6>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <label for="payment_method">{{ $t('orders.payment_method') }}</label>
                    <b-form-input
                      v-if="!editable"
                      :id="`payment_method`"
                      v-model="invoiceData.paymentMethod.title"
                      class="d-inline-block"
                      readonly
                    />
                    <v-select
                      v-else
                      :id="`payment_method`"
                      :value="invoiceData.paymentMethod.title"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="paymentValues"
                      class="w-100"
                      :clearable="false"
                      @input="setPymentMethod($event)"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            v-if="invoiceData.i_not_receive"
            cols="12"
            xl="4"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body>
                <h6 class="mb-2">
                  {{ $t('general.recipient') }}:
                </h6>
                <h6 class="mb-25">
                  {{ invoiceData.recipient.first_name }} {{ invoiceData.recipient.last_name }} {{ invoiceData.recipient.middle_name }}
                </h6>
                <p class="card-text mb-25">
                  {{ invoiceData.recipient.phone }}
                </p>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-card
          no-body
          class="invoice-preview-card"
        >
          <b-table-lite
            responsive
            :items="invoiceData.products"
            :fields="productTableColumns"
          >
            <template #cell(sku)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.product.sku }}
                <div v-if="data.item.drop" class="is_drop" v-text="`DROP`" />
              </b-card-text>
            </template>

            <template #cell(product)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.product.title }}
              </b-card-text>
            </template>

            <template #cell(quantity)="data">
              <b-card-text class="font-weight-bold mb-25">
                <b-form-input
                  v-model="data.item.quantity"
                  type="number"
                  class="d-inline-block"
                  :readonly="!editable"
                  @input="changeProductTotal(data.item)"
                />
              </b-card-text>
            </template>

            <template #cell(price)="data">
              <b-card-text class="font-weight-bold mb-25">
                <b-form-input
                  v-model="data.item.price"
                  type="number"
                  class="d-inline-block"
                  :readonly="!editable"
                  @input="changeProductTotal(data.item)"
                />
              </b-card-text>
            </template>

            <template #cell(sale_price)="data">
              <b-card-text class="font-weight-bold mb-25">
                <b-form-input
                  v-model="data.item.sale_price"
                  type="number"
                  class="d-inline-block"
                  :readonly="!editable"
                  @input="changeProductTotal(data.item)"
                />
              </b-card-text>
            </template>

            <template #cell(total)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ (data.item.sale_price > 0 ? data.item.sale_price : data.item.price) * data.item.quantity }} {{ $t('general.currencies.uah') }}
              </b-card-text>
            </template>

            <template #cell(actions)="data">
              <span
                v-if="editable"
                class="btn btn-danger btn-sm"
                @click="deleteProduct(data.item)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  class="align-middle"
                />
              </span>
            </template>
          </b-table-lite>

          <b-card-body
            v-if="editable"
            class="invoice-padding"
          >
            <label for="payment_method">{{ $t('orders.add_product') }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productsValues"
              :searchable="true"
              class="w-100"
              @search="searchProduct"
              @input="addProduct($event)"
            />
          </b-card-body>
        </b-card>

        <b-row>
          <b-col
            cols="12"
            xl="8"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body class="invoice-padding">
                <span class="font-weight-bold">{{ $t('orders.call_me') }}: </span>
                <span>{{ invoiceData.call_me ? $t('general.yes') : $t('general.no') }}</span>
              </b-card-body>
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">{{ $t('general.comment') }}: </span>
                <span>{{ invoiceData.comment || '-' }}</span>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <b-card>
              <table>
                <tbody>
                  <tr>
                    <td class="pr-1 pb-1">
                      {{ $t('orders.subtotal') }}:
                    </td>
                    <td class="pb-1">
                      <strong>
                        {{ invoiceData.totals[invoiceData.totals.findIndex(item => item.type === 'sub_total')].amount }} {{ $t('general.currencies.uah') }}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      {{ $t('orders.discount') }}:
                    </td>
                    <td>
                      <strong>
                        {{ invoiceData.totals[invoiceData.totals.findIndex(item => item.type === 'discount')].amount }} {{ $t('general.currencies.uah') }}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      {{ $t('orders.delivery') }}:
                    </td>
                    <td>
                      <strong>
                        {{ invoiceData.totals[invoiceData.totals.findIndex(item => item.type === 'delivery')].amount }} {{ $t('general.currencies.uah') }}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1 pt-1">
                      {{ $t('orders.total') }}:
                    </td>
                    <td class="pt-1">
                      <strong>
                        {{ invoiceData.totals[invoiceData.totals.findIndex(item => item.type === 'total')].amount }} {{ $t('general.currencies.uah') }}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

      </b-col>
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-if="!editable"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="makeEditable"
          >
            {{ $t('orders.make_editable') }}
          </b-button>
          <template v-else>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1"
              block
              @click="saveOrder"
            >
              {{ $t('orders.save_order') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              block
              @click="makeNotEditable"
            >
              {{ $t('orders.make_not_editable') }}
            </b-button>
          </template>
        </b-card>

        <b-card>
          <h4>{{ $t('orders.status_history') }}</h4>
          <br>
          <b-table-lite
            responsive
            :items="invoiceData.history"
            :fields="historyTableColumns"
          >
            <template #cell(status)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ $t(`orders.statuses.${data.item.status}`) }}
              </b-card-text>
            </template>
          </b-table-lite>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="changeStatus"
          >
            {{ $t('orders.change_status') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card>
          <h4>{{ $t('orders.changes_history') }}</h4>
          <br>
          <b-table-lite
            responsive
            :items="invoiceData.changes"
            :fields="changesTableColumns"
          />
          <br>
          <label for="adminComment">{{ $t('general.comment') }}</label>
          <b-form-textarea
            :id="`adminComment`"
            v-model="adminComment"
            class="d-inline-block"
            :placeholder="`Лише для адміна`"
          />
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            block
            @click="saveComment"
          >
            {{ $t('orders.save_comment') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'
import vSelect from 'vue-select'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BFormInput,
    BFormTextarea,
    vSelect,
  },
  mixins: [SweetAlertMixin],
  data() {
    return {
      editable: false,
      invoiceData: null,
      adminComment: '',
      delivery_methods: [],
      payment_methods: [],
      shops: [],
      cities: [],
      warehouses: [],
      products: [],
      productTableColumns: [
        { key: 'sku', label: this.$t('administration.table.fields.sku'), sortable: false },
        { key: 'product', label: this.$t('administration.table.fields.title'), sortable: false },
        { key: 'quantity', label: this.$t('administration.table.fields.quantity'), sortable: false },
        { key: 'price', label: `${this.$t('administration.table.fields.price')} (${this.$t('general.currencies.uah')})`, sortable: false },
        { key: 'sale_price', label: `${this.$t('administration.table.fields.sale_price')} (${this.$t('general.currencies.uah')})`, sortable: false },
        { key: 'total', label: this.$t('administration.table.fields.total'), sortable: false },
        { key: 'actions', label: this.$t('administration.table.fields.actions') },
      ],
      historyTableColumns: [
        { key: 'created_at', label: this.$t('administration.table.fields.created_at'), sortable: false },
        { key: 'status', label: this.$t('administration.table.fields.status'), sortable: false },
      ],
      changesTableColumns: [
        { key: 'created_at', label: this.$t('administration.table.fields.created_at'), sortable: false },
        { key: 'changes', label: this.$t('orders.changes_history_description'), sortable: false },
        { key: 'comment', label: this.$t('general.comment'), sortable: false },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
    deliveryValues() {
      return this.$options.filters.transformForVSelect(this.delivery_methods, 'key', 'title')
    },
    paymentValues() {
      return this.$options.filters.transformForVSelect(this.payment_methods, 'key', 'title')
    },
    shopsValues() {
      return this.$options.filters.transformForVSelect(this.shops, 'id', 'title')
    },
    citiesValues() {
      return this.$options.filters.transformForVSelect(this.cities, 'id', 'title')
    },
    warehousesValues() {
      return this.$options.filters.transformForVSelect(this.warehouses, 'id', 'title')
    },
    productsValues() {
      return this.$options.filters.transformForVSelect(this.products, 'id', 'title')
    },
  },
  beforeCreate() {
    this.$http.get(`/api/administration/orders/${router.currentRoute.params.id}`)
      .then(response => {
        this.invoiceData = this.transformData(response.data.data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.invoiceData = null
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'orders-index' })
        }
      })
    this.$http.get('/api/promo/delivery_methods/')
      .then(response => {
        this.delivery_methods = response.data.data
      })
      .catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.response.errors,
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    this.$http.get('/api/promo/payment_methods/')
      .then(response => {
        this.payment_methods = response.data.data
      })
      .catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.response.errors,
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    this.$http.get('/api/promo/shops/')
      .then(response => {
        this.shops = response.data.data
      })
      .catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.response.errors,
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
  },
  methods: {
    changeStatus() {
      this.confirm(result => {
        this.$http.post(`/api/administration/orders/${router.currentRoute.params.id}/status`, { status: result.value })
          .then(() => {
            this.$http.get(`/api/administration/orders/${router.currentRoute.params.id}`)
              .then(response => {
                const { data } = response.data

                this.invoiceData = data
              })
              .catch(error => {
                if (error.response.status === 404) {
                  this.invoiceData = null
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: this.$t('notifications.not_found'),
                      icon: 'AlertCircleIcon',
                      variant: 'warning',
                    },
                  })
                  router.replace({ name: 'orders-index' })
                }
              })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                text: this.$t('notifications.success.updated'),
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
      }, {
        input: 'select',
        inputOptions: {
          new: this.$t('orders.statuses.new'),
          paid: this.$t('orders.statuses.paid'),
          inwork: this.$t('orders.statuses.inwork'),
          completed: this.$t('orders.statuses.completed'),
          cancel: this.$t('orders.statuses.cancel'),
          delivering: this.$t('orders.statuses.delivering'),
        },
        text: this.$t('notifications.confirm.update.text'),
      })
    },
    printInvoice() {
      window.print()
    },
    setDeliveryMethod(ev) {
      this.invoiceData.deliveryMethod = _.head(_.filter(this.delivery_methods, ['key', ev.value]))
    },
    setPymentMethod(ev) {
      this.invoiceData.paymentMethod = _.head(_.filter(this.payment_methods, ['key', ev.value]))
    },
    setShop(ev) {
      this.invoiceData.shop = _.head(_.filter(this.shops, ['id', ev.value]))
    },
    setCity(ev) {
      this.invoiceData.city = _.head(_.filter(this.cities, ['id', ev.value]))
      this.$http.get(`/api/warehouses/?city_id=${this.invoiceData.city.id}`).then(res => {
        this.warehouses = res.data.data
      })
    },
    setWarehouse(ev) {
      this.invoiceData.warehouse = _.head(_.filter(this.warehouses, ['id', ev.value]))
    },
    filterCities(search, loading) {
      if (search.length) {
        loading(true)
        this.searchCity(loading, search, this)
      }
    },
    searchCity: _.debounce((loading, search, vm) => {
      vm.$http.get(`/api/cities/?query=${search}`).then(res => {
        vm.cities = res.data.data
        loading(false)
      })
    }, 350),
    filterWarehouses(search, loading) {
      if (search.length) {
        loading(true)
        this.searchWarehouse(loading, search, this)
      }
    },
    searchWarehouse: _.debounce((loading, search, vm) => {
      vm.$http.get(`/api/warehouses/?city_id=${vm.invoiceData.city.id}&query=${search}`).then(res => {
        vm.warehouses = res.data.data
        loading(false)
      })
    }, 350),
    makeEditable() {
      this.editable = !this.editable
    },
    transformData(item) {
      const data = {
        id: item.id,
        bx_deal_id: item.bx_deal_id,
        call_me: item.call_me,
        comment: item.comment,
        coupon: item.coupon,
        created: item.created,
        created_at: item.created_at,
        email: item.email,
        i_not_receive: item.i_not_receive,
        phone: item.phone,
        history: item.history,
        changes: item.changes,
        totals: item.totals,
        recipient: item.recipient,
        user: item.user,
        products: [],
        deliveryMethod: item.deliveryMethod,
        paymentMethod: item.paymentMethod,

        address: {},
        city: {},
        shop: {},
        warehouse: {},
      }

      if (!_.isNull(item.products)) {
        _.each(item.products, pr => {
          data.products.push({
            id: pr.id,
            price: pr.price,
            sale_price: pr.sale_price,
            quantity: pr.quantity,
            product_id: pr.product.id,
            product: pr.product,
            drop: _.some(pr.product.stocks, ['address', 'drop']) && (!_.some(pr.product.stocks, ['address', 'one_ec']) || _.some(pr.product.stocks, { address: 'one_ec', quantity: 0 })),
          })
        })
      }

      if (!_.isNull(item.address)) {
        data.address = item.address
      } else {
        data.address = {
          street: this.$i18n.t('orders.nodata'),
          number: this.$i18n.t('orders.nodata'),
          flat: this.$i18n.t('orders.nodata'),
        }
      }

      if (!_.isNull(item.city)) {
        data.city = item.city
      } else {
        data.city = {
          title: this.$i18n.t('orders.nodata'),
        }
      }

      if (!_.isNull(item.shop)) {
        data.shop = item.shop
      } else {
        data.shop = {
          title: this.$i18n.t('orders.nodata'),
        }
      }

      if (!_.isNull(item.warehouse)) {
        data.warehouse = item.warehouse
      } else {
        data.warehouse = {
          title: this.$i18n.t('orders.nodata'),
        }
      }

      return data
    },
    makeNotEditable() {
      this.editable = false
    },
    changeProductTotal(item) {
      if (!item.sale_price || item.sale_price === '') {
        item.sale_price = 0
      }
      item.total = (item.sale_price ? item.sale_price : item.price) * item.quantity
      this.changeTotals()
    },
    deleteProduct(item) {
      this.invoiceData.products = _.filter(this.invoiceData.products, p => p.product_id !== item.product_id)
      this.changeTotals()
    },
    async searchProduct(query) {
      await this.$http.get('/api/administration/products', {
        params: { query },
      })
        .then(response => {
          this.products = response.data.data
        })
    },
    addProduct(ev) {
      const product = _.head(_.filter(this.products, ['id', ev.value]))
      this.invoiceData.products.push({
        price: product.prices[0].price,
        sale_price: 0,
        quantity: 1,
        product_id: product.id,
        product,
      })
      this.changeTotals()
    },
    changeTotals() {
      // const tmpTotals = {
      //   ...this.invoiceData.totals
      // }
      this.invoiceData.totals = []
      this.invoiceData.totals.push({
        type: 'sub_total',
        amount: _.reduce(this.invoiceData.products, (sum, p) => sum + ((p.sale_price > 0 ? p.sale_price : p.price) * p.quantity), 0),
      })
      this.invoiceData.totals.push({
        type: 'delivery',
        amount: 0,
      })
      this.invoiceData.totals.push({
        type: 'discount',
        amount: 0,
      })
      this.invoiceData.totals.push({
        type: 'total',
        amount: _.reduce(this.invoiceData.products, (sum, p) => sum + ((p.sale_price > 0 ? p.sale_price : p.price) * p.quantity), 0),
      })
    },
    saveOrder() {
      this.$http.put(`/api/administration/orders/${this.invoiceData.id}`, {
        phone: this.invoiceData.user.phone,
        email: this.invoiceData.user.email,
        delivery_method: this.invoiceData.deliveryMethod.key,
        payment_method: this.invoiceData.paymentMethod.key,
        i_not_receive: this.invoiceData.i_not_receive ? this.invoiceData.i_not_receive : false,
        city_id: this.invoiceData.city.id,
        warehouse_id: this.invoiceData.warehouse.id,
        user: this.invoiceData.user,
        recipient: [],
        address: this.invoiceData.address,
        shop_id: this.invoiceData.shop.id,
        coupon_code: this.invoiceData.coupon_code,
        call_me: this.invoiceData.call_me ? this.invoiceData.call_me : false,
        products: this.invoiceData.products,
        totals: this.invoiceData.totals,
        comment: this.adminComment,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success!',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
          this.editable = false

          this.$http.get(`/api/administration/orders/${router.currentRoute.params.id}`)
            .then(response => {
              this.invoiceData = this.transformData(response.data.data)
            })
            .catch(error => {
              if (error.response.status === 404) {
                this.invoiceData = null
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('notifications.not_found'),
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                  },
                })
                router.replace({ name: 'orders-index' })
              }
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    saveComment() {
      this.$http.post(`/api/administration/orders/${this.invoiceData.id}/comment`, {
        comment: this.adminComment,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success!',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
          this.adminComment = null
          this.$http.get(`/api/administration/orders/${router.currentRoute.params.id}`)
            .then(response => {
              this.invoiceData = this.transformData(response.data.data)
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    isDrop(product) {
      return _.some(product.stocks, ['address', 'drop']) && _.some(product.stocks, { address: 'one_ec', quantity: 0 })
    },
  },
}
</script>

<style lang="scss">
[dir] .table th, [dir] .table td {
  padding: 0.72rem 1rem;
}

.is_drop {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 13px;
  line-height: 100%;
  padding: 4px;
  color: #fff;
  border: 1px solid #ff6700;
  background: #ff6700;
  border-radius: 4px;
  margin: 5px 0 0;
  width: max-content;
}

@media print {
  body {
    background-color: transparent !important;
  }

  .invoice-actions,
  .customizer-toggle,
  footer.footer,
  .breadcrumbs-top,
  nav.header-navbar,
  .main-menu,
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
