export default [
  {
    header: 'administration.navigation.others',
  },
  {
    title: 'administration.navigation.access_control',
    route: 'access-control',
    icon: 'ShieldIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'administration.navigation.orders',
    route: 'orders-index',
    icon: 'DollarSignIcon',
    action: 'index',
    resource: 'orders',
  },
  {
    title: 'administration.navigation.reviews',
    route: 'reviews-index',
    icon: 'PenToolIcon',
    action: 'index',
    resource: 'reviews',
  },
  {
    title: 'administration.navigation.messages',
    route: 'messages-index',
    icon: 'MailIcon',
    action: 'index',
    resource: 'messages',
  },
  {
    title: 'administration.navigation.extensions',
    route: 'extensions-index',
    icon: 'ExternalLinkIcon',
    action: 'index',
    resource: 'extensions',
  },
  {
    title: 'administration.navigation.navigations',
    route: 'navigations-index',
    icon: 'Navigation2Icon',
    action: 'index',
    resource: 'navigations',
  },
  // {
  //   title: 'administration.navigation.coupons',
  //   route: 'coupons-index',
  //   icon: 'CreditCardIcon',
  //   action: 'index',
  //   resource: 'coupons',
  // },
]
