<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('administration.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_h1.label')"
                        :label-for="'meta_h1.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_h1.' + language.code"
                          v-model="item.meta_h1[language.code]"
                          :state="errors && errors['meta_h1.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                          {{ errors['meta_h1.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_title.label')"
                        :label-for="'meta_title.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_title.' + language.code"
                          v-model="item.meta_title[language.code]"
                          :state="errors && errors['meta_title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                          {{ errors['meta_title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_description.label')"
                        :label-for="'meta_description.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_description.' + language.code"
                          v-model="item.meta_description[language.code]"
                          :state="errors && errors['meta_description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                          {{ errors['meta_description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_keywords.label')"
                        :label-for="'meta_keywords.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_keywords.' + language.code"
                          v-model="item.meta_keywords[language.code]"
                          :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                          {{ errors['meta_keywords.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.canonical.label')"
                        :label-for="'canonical.' + language.code"
                      >
                        <b-form-input
                          :id="'canonical.' + language.code"
                          v-model="item.canonical[language.code]"
                          :state="errors && errors['canonical.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                          {{ errors['canonical.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <quill-editor
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :options="editorOptions"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.data.label')"
              @click="loadDataTab"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.categories.label')"
                    label-for="categories"
                    :state="errors && errors.categories ? false : null"
                  >
                    <treeselect
                      id="categories"
                      v-model="item.categories"
                      :multiple="true"
                      :options="categoryOptions"
                      :normalizer="normalizer"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.categories">
                      {{ errors.categories[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.stickers.label')"
                    label-for="stickers"
                    :state="errors && errors.stickers ? false : null"
                  >
                    <v-select
                      id="stickers"
                      v-model="item.stickers"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stickersOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :multiple="true"
                      input-id="stickers"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.stickers">
                      {{ errors.stickers[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.indexing_status.label')"
                    label-for="indexing_status"
                    :state="errors && errors.indexing_status ? false : null"
                  >
                    <v-select
                      id="indexing_status"
                      v-model="item.indexing_status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="indexing_status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.indexing_status">
                      {{ errors.indexing_status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="hidden"
                >
                  <b-form-group
                    :label="$t('form.code.label')"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      v-model="item.code"
                      :state="errors && errors.code ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.code">
                      {{ errors.code[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.sku.label')"
                    label-for="sku"
                  >
                    <b-form-input
                      id="sku"
                      v-model="item.sku"
                      :state="errors && errors.sku ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.sku">
                      {{ errors.sku[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="hidden"
                >
                  <b-form-group
                    :label="$t('form.upc.label')"
                    label-for="upc"
                  >
                    <b-form-input
                      id="upc"
                      v-model="item.upc"
                      :state="errors && errors.upc ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.upc">
                      {{ errors.upc[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="hidden"
                >
                  <b-form-group
                    :label="$t('form.ean.label')"
                    label-for="ean"
                  >
                    <b-form-input
                      id="ean"
                      v-model="item.ean"
                      :state="errors && errors.ean ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.ean">
                      {{ errors.ean[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.isbn2.label')"
                    label-for="isbn"
                  >
                    <b-form-input
                      id="isbn"
                      v-model="item.isbn"
                      :state="errors && errors.isbn ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.isbn">
                      {{ errors.isbn[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="hidden"
                >
                  <b-form-group
                    :label="$t('form.min_quantity.label')"
                    label-for="min_quantity"
                  >
                    <b-form-input
                      id="min_quantity"
                      v-model="item.min_quantity"
                      type="number"
                      :state="errors && errors.min_quantity ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.min_quantity">
                      {{ errors.min_quantity[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.available_at.label')"
                    label-for="available_at"
                    :state="errors && errors.available_at ? false : null"
                  >
                    <b-form-datepicker
                      id="available_at"
                      v-model="item.available_at"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.available_at">
                      {{ errors.available_at[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.novelty_until.label')"
                    label-for="novelty_until"
                    :state="errors && errors.novelty_until ? false : null"
                  >
                    <b-form-datepicker
                      id="novelty_until"
                      v-model="item.novelty_until"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.novelty_until">
                      {{ errors.novelty_until[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.stock_status.label')"
                    label-for="stock_status"
                    :state="errors && errors.stock_status ? false : null"
                  >
                    <v-select
                      id="stock_status"
                      v-model="item.stock_status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stockStatusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="stock_status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.stock_status">
                      {{ errors.stock_status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.1c_id.label')"
                    label-for="1c_id"
                  >
                    <b-form-input
                      id="1c_id"
                      v-model="item['1c_id']"
                      :state="errors && errors['1c_id'] ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors['1c_id']">
                      {{ errors['1c_id'][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.bundle.label')"
                    label-for="bundle_status"
                    :state="errors && errors.bundle_status ? false : null"
                  >
                    <v-select
                      id="bundle_status"
                      v-model="item.bundle_status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="bundle_status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.bundle_status">
                      {{ errors.bundle_status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('administration.products.tabs.images.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="$t('form.avatar.label')">
                    <!-- media -->
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.avatar"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.avatar = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="avatar"
                          type="product_avatar"
                          :multiple="false"
                          @fm-selected="selectedAvatar"
                        />

                        <b-row
                          v-if="errors && errors.avatar"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.avatar[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['avatar.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['avatar.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                    <!--/ media -->
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group :label="$t('form.gallery.label')">
                    <b-media no-body>
                      <b-media-aside
                        v-for="(file, index) in item.gallery"
                        :key="index"
                      >
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="file.url"
                            height="80"
                          />
                        </b-link>
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.gallery.splice(index, 1)"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="gallery"
                          type="product_gallery"
                          :multiple="true"
                          @fm-selected="selectedGallery"
                        />

                        <b-row
                          v-if="errors && errors.avatar"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.avatar[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['avatar.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['avatar.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                    <!--/ media -->
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.video.label')"
                    label-for="video"
                  >
                    <b-row>
                      <b-col cols="2">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          block
                          @click="addVideo"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                        </b-button>
                      </b-col>
                      <b-col cols="10">
                        <div class="repeater-form">
                          <b-row
                            v-for="(video, index) in item.video"
                            :id="'video' + index"
                            :key="index"
                            ref="row"
                          >
                            <b-col
                              cols="10"
                            >
                              <b-form-input
                                :id="'videos.' + index"
                                v-model="item.video[index]"
                                :placeholder="video"
                              />
                            </b-col>
                            <!-- Remove Button -->
                            <b-col cols="2">
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                class="mt-2 mt-md-0"
                                block
                                @click="removeVideo(index)"
                              >
                                <feather-icon
                                  icon="XIcon"
                                  class="mr-25"
                                />
                              </b-button>
                            </b-col>
                            <b-col cols="12">
                              <hr>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>

                    <b-form-invalid-feedback v-if="errors && errors.video">
                      {{ errors.video[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.stocks.label')"
              @click="loadStocks"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(stock, index) in item.stocks"
                  :id="'stock' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.stocks.label')"
                      :label-for="'stocks.' + index + '.stock_id'"
                      :state="errors && errors['stocks.' + index + '.stock_id'] ? false : null"
                    >
                      <v-select
                        :id="'stocks.' + index + '.stock_id'"
                        v-model="stock.stock_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="stocksOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'stocks.' + index + '.stock_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['stocks.' + index + '.stock_id']">
                        {{ errors['stocks.' + index + '.stock_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.quantity.label')"
                      :label-for="'stocks.' + index + '.quantity'"
                    >
                      <b-form-input
                        :id="'stocks.' + index + '.quantity'"
                        v-model="stock.quantity"
                        :state="errors && errors['stocks.' + index + '.quantity'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['stocks.' + index + '.quantity']">
                        {{ errors['stocks.' + index + '.quantity'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeStock(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addStock"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.stocks"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.stocks[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.prices.label')"
              @click="loadRoles"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(price, index) in item.prices"
                  :id="'price' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.role.label')"
                      :label-for="'prices.' + index + '.role_id'"
                      :state="errors && errors['prices.' + index + '.role_id'] ? false : null"
                    >
                      <v-select
                        :id="'prices.' + index + '.role_id'"
                        v-model="price.role_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="rolesOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'prices.' + index + '.role_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.role_id']">
                        {{ errors['prices.' + index + '.role_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.price.label')"
                      :label-for="'prices.' + index + '.price'"
                    >
                      <b-form-input
                        :id="'prices.' + index + '.price'"
                        v-model="price.price"
                        :state="errors && errors['prices.' + index + '.price'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.price']">
                        {{ errors['prices.' + index + '.price'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removePrice(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addPrice"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.prices"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.prices[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.bundle_status === 'enabled'"
              :title="$t('administration.products.tabs.bundle.label')"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(set, index) in item.sets"
                  :id="'set' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.product.label')"
                      :label-for="'sets.' + index + '.entity_id'"
                      :state="errors && errors['sets.' + index + '.entity_id'] ? false : null"
                    >
                      <v-select
                        :id="'sets.' + index + '.entity_id'"
                        v-model="set.entity_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="productOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'sets.' + index + '.entity_id'"
                        :searchable="true"
                        @search="searchProduct"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['sets.' + index + '.entity_id']">
                        {{ errors['sets.' + index + '.entity_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.quantity.label')"
                      :label-for="'sets.' + index + '.price'"
                    >
                      <b-form-input
                        :id="'sets.' + index + '.price'"
                        v-model="set.price"
                        :state="errors && errors['sets.' + index + '.price'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['sets.' + index + '.price']">
                        {{ errors['sets.' + index + '.price'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeSet(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addSet"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.sets"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.sets[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.discounts.label')"
              @click="loadRoles"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(discount, index) in item.discounts"
                  :id="'discount' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('form.role.label')"
                      :label-for="'discounts.' + index + '.role_id'"
                      :state="errors && errors['discounts.' + index + '.role_id'] ? false : null"
                    >
                      <v-select
                        :id="'discounts.' + index + '.role_id'"
                        v-model="discount.role_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="rolesOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'discounts.' + index + '.role_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.role_id']">
                        {{ errors['discounts.' + index + '.role_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="1"
                  >
                    <b-form-group
                      :label="$t('form.percent.label')"
                      :label-for="'discounts.' + index + '.percent'"
                    >
                      <b-form-input
                        :id="'discounts.' + index + '.percent'"
                        v-model="discount.percent"
                        :type="`number`"
                        :step="`1`"
                        :min="`1`"
                        :max="`99`"
                        @input="setDiscountPercent(index, $event)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('form.price.label')"
                      :label-for="'discounts.' + index + '.price'"
                    >
                      <b-form-input
                        :id="'discounts.' + index + '.price'"
                        :ref="'discounts' + index + 'price'"
                        v-model="discount.price"
                        :type="`number`"
                        :step="`1`"
                        :state="errors && errors['discounts.' + index + '.price'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.price']">
                        {{ errors['discounts.' + index + '.price'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('form.started_at.label')"
                      :label-for="'discounts.' + index + '.started_at'"
                      :state="errors && errors['discounts.' + index + '.started_at'] ? false : null"
                    >
                      <b-form-datepicker
                        :id="'discounts.' + index + '.started_at'"
                        v-model="discount.started_at"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.started_at']">
                        {{ errors['discounts.' + index + '.started_at'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('form.ended_at.label')"
                      :label-for="'discounts.' + index + '.ended_at'"
                      :state="errors && errors['discounts.' + index + '.ended_at'] ? false : null"
                    >
                      <b-form-datepicker
                        :id="'discounts.' + index + '.ended_at'"
                        v-model="discount.ended_at"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.ended_at']">
                        {{ errors['discounts.' + index + '.ended_at'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeDiscount(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addDiscount"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.discounts"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.discounts[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.attributes.label')"
              @click="loadAttributes"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(attribute, index) in item.attributes"
                  :id="'attribute' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.attribute_id.label')"
                      :label-for="'attributes.' + index + '.attribute_id'"
                      :state="errors && errors['attributes.' + index + '.attribute_id'] ? false : null"
                    >
                      <v-select
                        :id="'attributes.' + index + '.attribute_id'"
                        v-model="attribute.attribute_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="attributesOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'attributes.' + index + '.attribute_id'"
                        @input="loadAttributeValue($event, index)"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_id']">
                        {{ errors['attributes.' + index + '.attribute_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.attribute_value_id.label')"
                      :label-for="'attributes.' + index + '.attribute_value_id'"
                      :state="errors && errors['attributes.' + index + '.attribute_value_id'] ? false : null"
                    >
                      <v-select
                        :id="'attributes.' + index + '.attribute_value_id'"
                        v-model="attribute.attribute_value_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                        :disabled="!attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'attributes.' + index + '.attribute_value_id'"
                        :taggable="true"
                        :create-option="val => ({ value: `new_${val}`, label: `Добавить новое значение ${val}` })"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_value_id']">
                        {{ errors['attributes.' + index + '.attribute_value_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.is_link.label')"
                      :label-for="'attributes.' + index + '.is_link'"
                    >
                      <b-form-checkbox
                        :id="'attributes.' + index + '.is_link'"
                        v-model="attribute.is_link"
                        :state="errors && errors['attributes.' + index + '.is_link'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.is_link']">
                        {{ errors['attributes.' + index + '.' + '.is_link'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeAttribute(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addAttribute"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.attributes"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.attributes[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('administration.navigation.extensions')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.top_extension_id.label')"
                    label-for="top_extension_id"
                    :state="errors && errors.top_extension_id ? false : null"
                  >
                    <v-select
                      id="top_extension_id"
                      v-model="item.top_extension_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionOptions"
                      :reduce="val => val.value"
                      input-id="top_extension_id"
                      :searchable="true"
                      @search="searchExtension"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.top_extension_id">
                      {{ errors.top_extension_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.bottom_extension_id.label')"
                    label-for="bottom_extension_id"
                    :state="errors && errors.bottom_extension_id ? false : null"
                  >
                    <v-select
                      id="bottom_extension_id"
                      v-model="item.bottom_extension_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionOptions"
                      :reduce="val => val.value"
                      input-id="bottom_extension_id"
                      :searchable="true"
                      @search="searchExtension"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.bottom_extension_id">
                      {{ errors.bottom_extension_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'products-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import htmlEditButton from 'quill-html-edit-button'
import EditorMixin from '@/mixins/EditorMixin'

import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormInvalidFeedback,
  BMedia,
  BMediaBody,
  BMediaAside,
  BLink,
  BImg,
  BFormFile,
  BFormTextarea,
  BFormDatepicker,
  BOverlay,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'
import StatusMixin from '@/mixins/StatusMixin'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'
import InitFileManager from '@/components/InitFileManager'

Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BImg,
    BFormFile,
    BFormTextarea,
    quillEditor,
    Treeselect,
    BFormDatepicker,
    BOverlay,
    InitFileManager,
  },
  mixins: [CategoryMixin, SweetAlertMixin, StatusMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      stockStatusOptions: [
        { label: this.$t('general.stock_statuses.available'), value: 'available' },
        { label: this.$t('general.stock_statuses.not_available'), value: 'not_available' },
        { label: this.$t('general.stock_statuses.waiting'), value: 'waiting' },
        { label: this.$t('general.stock_statuses.pre_order'), value: 'pre_order' },
      ],
      categoryOptions: [],
      stickersOptions: [],
      stocksOptions: [],
      rolesOptions: [],
      attributesOptions: [],
      attributeValuesOptions: [],
      extensionOptions: [],
      productOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    this.item = await this.transformData()
    this.loadRoles()
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    selectedAvatar(data) {
      this.item.avatar = data
    },
    selectedGallery(data) {
      this.item.gallery = data
    },
    async searchProduct(query) {
      await this.$http.get('/api/administration/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.productOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          if (this.item.sets?.length) {
            _.each(this.item.sets, set => {
              if (set.entity_id) {
                this.$http.get(`/api/administration/products/${set.entity_id}`)
                  .then(res => {
                    this.productOptions.push({ label: res.data.data.title, value: set.entity_id })
                    this.productOptions = _.uniqBy(this.productOptions, 'value')
                  })
              }
            })
          }
        })
    },
    async searchExtension(query) {
      await this.$http.get('/api/administration/extensions', {
        params: {
          query,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async loadDataTab() {
      if (!Object.keys(this.categoryOptions).length) {
        await this.$http.get('/api/administration/categories', {
          params: {
            type: 'product',
            status: 'enabled',
          },
        })
          .then(response => {
            this.categoryOptions = response.data
          })
      }
      if (!Object.keys(this.stickersOptions).length) {
        await this.$http.get('/api/stickers', { params: { status: 'enabled', per_page: 100 } })
          .then(response => {
            this.stickersOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          })
      }
    },
    loadStocks() {
      if (Object.keys(this.stocksOptions).length) {
        return
      }

      this.$http.get('/api/stocks', { params: { status: 'enabled', per_page: 100 } })
        .then(response => {
          this.stocksOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    loadRoles() {
      if (Object.keys(this.rolesOptions).length) {
        return
      }

      this.$http.get('/api/roles', { params: { mode: 'client', per_page: 100 } })
        .then(response => {
          this.rolesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    loadAttributes() {
      if (Object.keys(this.attributesOptions).length) {
        return
      }

      this.$http.get('/api/attributes', {
        params: {
          sort_by: 'title', sort_desc: false, status: 'enabled', per_page: 200,
        },
      })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async loadAttributeValue(id, index) {
      this.item.attributes[index].attribute_value_id = null

      if (this.attributeValuesOptions && _.has(this.attributeValuesOptions, `attribute_${id}`)) {
        return
      }

      const response = await this.$http.get(`/api/attributes/${id}/values`)

      this.$set(this.attributeValuesOptions, `attribute_${id}`, await this.$options.filters.transformForVSelect(response.data.data, 'id', 'title'))
    },
    async onSubmit() {
      const data = {
        title: this.item.title,
        video: this.item.video.join(','),
        slug: this.item.slug,
        '1c_id': this.item['1c_id'],
        categories: this.item.categories,
        stickers: this.item.stickers,
        stocks: this.item.stocks,
        prices: this.item.prices,
        sets: this.item.sets,
        discounts: this.item.discounts,
        attributes: this.item.attributes,
        avatar: this.item.avatar,
        gallery: this.item.gallery,
        status: this.item.status,
        bundle_status: this.item.bundle_status,
        indexing_status: this.item.indexing_status,
        description: this.item.description,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        code: this.item.code,
        sku: this.item.sku,
        upc: this.item.upc,
        ean: this.item.ean,
        isbn: this.item.isbn,
        min_quantity: this.item.min_quantity,
        available_at: this.item.available_at,
        novelty_until: this.item.novelty_until,
        stock_status: this.item.stock_status,
        top_extension_id: this.item.top_extension_id,
        bottom_extension_id: this.item.bottom_extension_id,
      }

      this.$http.post('/api/administration/products', data)
        .then(() => {
          router.replace({ name: 'products-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    addStock() {
      this.item.stocks.push({
        quantity: 1,
        stock_id: null,
      })
    },
    removeStock(index) {
      this.item.stocks.splice(index, 1)
    },
    addPrice() {
      this.item.prices.push({
        price: 1,
        role_id: null,
      })
    },
    removePrice(index) {
      this.item.prices.splice(index, 1)
    },
    addSet() {
      this.item.sets.push({
        price: 1,
        entity_id: null,
      })
    },
    removeSet(index) {
      this.item.sets.splice(index, 1)
    },
    addDiscount() {
      this.item.discounts.push({
        price: 1,
        started_at: new Date(),
        ended_at: new Date(),
        role_id: null,
      })
    },
    removeDiscount(index) {
      this.item.discounts.splice(index, 1)
    },
    addAttribute() {
      this.item.attributes.push({
        attribute_id: null,
        attribute_value_id: null,
        is_link: false,
      })
    },
    removeAttribute(index) {
      this.item.attributes.splice(index, 1)
    },
    addVideo() {
      this.item.video.push('')
    },
    removeVideo(index) {
      this.item.video.splice(index, 1)
    },
    transformData() {
      const data = {
        slug: null,
        video: [],
        '1c_id': null,
        status: 'enabled',
        code: null,
        sku: null,
        upc: null,
        ean: null,
        isbn: null,
        min_quantity: 1,
        available_at: new Date(),
        novelty_until: new Date(),
        stock_status: 'available',
        bundle_status: 'disabled',
        indexing_status: 'enabled',
        avatar: null,
        gallery: null,
        categories: null,
        stickers: null,
        stocks: [],
        prices: [],
        sets: [],
        discounts: [],
        attributes: [],
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
        top_extension_id: null,
        bottom_extension_id: null,
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
        data.meta_description[language.code] = null
        data.meta_h1[language.code] = null
        data.meta_keywords[language.code] = null
        data.meta_title[language.code] = null
        data.canonical[language.code] = null
      })

      return data
    },
    setDiscountPercent(ref, ev) {
      const disc = (this.item.prices['0'].price - ((this.item.prices['0'].price * ev) / 100))
      this.item.discounts[ref].price = parseInt(disc, 10)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
