<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>
              <b-row>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    :label-for="'title.' + language.code"
                  >
                    <b-form-input
                      :id="'title.' + language.code"
                      v-model="item.title[language.code]"
                      :state="errors && errors['title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                      {{ errors['title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.slug.label')"
                label-for="slug"
              >
                <b-form-input
                  id="slug"
                  v-model="item.slug"
                  :state="errors && errors.slug ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.slug">
                  {{ errors.slug[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.position.label')"
                label-for="position"
              >
                <b-form-input
                  id="position"
                  v-model="item.position"
                  :state="errors && errors.position ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.position">
                  {{ errors.position[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.status.label')"
                label-for="status"
                :state="errors && errors.status ? false : null"
              >
                <v-select
                  id="status"
                  v-model="item.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="status"
                />
                <b-form-invalid-feedback v-if="errors && errors.status">
                  {{ errors.status[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.type.label')"
                label-for="type"
                :state="errors && errors.type ? false : null"
              >
                <v-select
                  id="type"
                  v-model="item.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="type"
                />
                <b-form-invalid-feedback v-if="errors && errors.type">
                  {{ errors.type[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              v-if="item.type !== 'slider'"
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.logical.label')"
                label-for="logical"
                :state="errors && errors.logical ? false : null"
              >
                <v-select
                  id="logical"
                  v-model="item.logical"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="logicalOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="logical"
                />
                <b-form-invalid-feedback v-if="errors && errors.logical">
                  {{ errors.logical[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.unit.label')"
                label-for="position"
              >
                <b-form-input
                  id="unit"
                  v-model="item.unit"
                  :state="errors && errors.unit ? false : null"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.enabled_for_filter.label')"
                label-for="enabled_for_filter"
              >
                <b-form-checkbox
                  id="enabled_for_filter"
                  v-model="item.enabled_for_filter"
                />
                <b-form-invalid-feedback v-if="errors && errors.enabled_for_filter">
                  {{ errors.enabled_for_filter[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="repeater-form">
            <b-tabs
              v-for="(value, index) in item.values"
              :key="index"
            >
              <b-tab
                v-for="language in languages"
                :key="language.code"
              >
                <template #title>
                  <b-img
                    :src="require('@/assets/images/flags/' + language.code + '.svg')"
                    height="16"
                    width="16"
                    class="mr-1"
                  />
                  <span class="d-none d-sm-inline">{{ language.title }}</span>
                </template>
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.title.label')"
                      :label-for="'values.' + index + '.title.' + language.code"
                    >
                      <b-form-input
                        :id="'values.' + index + '.title.' + language.code"
                        v-model="value.title[language.code]"
                        :state="errors && errors['values.' + index + '.title.' + language.code] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['values.' + index + '.title.' + language.code]">
                        {{ errors['values.' + index + '.title.' + language.code][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.slug.label')"
                      :label-for="'values.' + index + '.slug.' + language.code"
                    >
                      <b-form-input
                        :id="'values.' + index + '.slug.' + language.code"
                        v-model="value.slug"
                        :state="errors && errors['values.' + index + '.slug'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['values.' + index + '.slug']">
                        {{ errors['values.' + index + '.slug'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.enabled_for_filter.label')"
                      :label-for="'values.' + index + '.enabled_for_filter.' + language.code"
                    >
                      <b-form-checkbox
                        :id="'values.' + index + '.enabled_for_filter.' + language.code"
                        v-model="value.enabled_for_filter"
                        :state="errors && errors['values.' + index + '.enabled_for_filter'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['values.' + index + '.enabled_for_filter']">
                        {{ errors['values.' + index + '.enabled_for_filter'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeValue(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </div>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addValue"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>{{ $t('general.add') }}</span>
          </b-button>

          <b-row
            v-if="errors && errors.values"
          >
            <b-col cols="12">
              <b-form-invalid-feedback :state="false">
                {{ errors.values[0] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'attributes-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BFormInvalidFeedback,
  BImg, BFormFile, BFormTextarea, BFormDatepicker, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import StatusMixin from '@/mixins/StatusMixin'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BFormInvalidFeedback,
    vSelect,
    BImg,
    BFormFile,
    BFormTextarea,
    BFormDatepicker,
    BOverlay,
    BFormCheckbox,
  },
  mixins: [SweetAlertMixin, StatusMixin],
  data() {
    return {
      item: null,
      languages: [],
      typeOptions: [
        { label: this.$t('general.attribute_types.checkbox'), value: 'checkbox' },
        { label: this.$t('general.attribute_types.slider'), value: 'slider' },
      ],
      logicalOptions: [
        { label: this.$t('general.attribute_logical.and'), value: 'and' },
        { label: this.$t('general.attribute_logical.or'), value: 'or' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/attributes/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'attributes-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    addValue() {
      const data = {
        title: {},
        enabled_for_filter: false,
        slug: null,
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
      })

      this.item.values.push(data)
    },
    removeValue(index) {
      this.item.values.splice(index, 1)
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        position: this.item.position,
        status: this.item.status,
        type: this.item.type,
        logical: this.item.type !== 'slider' ? this.item.logical : 'and',
        unit: this.item.unit,
        slug: this.item.slug,
        enabled_for_filter: this.item.enabled_for_filter,
        values: this.item.values,
      }

      this.$http.put(`/api/attributes/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'attributes-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        position: item.position,
        status: item.status,
        type: item.type,
        logical: item.logical,
        unit: item.unit,
        slug: item.slug,
        enabled_for_filter: item.enabled_for_filter,
        title: {},
        values: [],
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
      })

      data.values = _.reduce(item.values, (result, value) => {
        const valueData = {
          id: value.id,
          enabled_for_filter: value.enabled_for_filter,
          slug: value.slug,
          title: {},
        }

        _.each(this.languages, language => {
          const translation = _.find(value.translations, { locale: language.code })

          valueData.title[language.code] = translation ? translation.title : null
        })

        result.push(valueData)
        return result
      }, [])

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
