/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
import appsAndPages from './apps-and-pages'
import users from './users'
import system from './system'
import feeds from './feeds'
import others from './others'
import catalog from './catalog'
import posts from './posts'
import pages from './pages'
import seo from './seo'

// Array of sections
export default [...dashboard, ...appsAndPages, ...catalog, ...posts, ...pages, ...users, ...seo, ...others, ...feeds, ...system]
