<template>
  <component :is="item === undefined ? 'div' : 'div'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-card>
              <b-form>
                <b-tabs>
                  <b-tab
                    v-for="language in languages"
                    :key="language.code"
                  >
                    <template #title>
                      <b-img
                        :src="require('@/assets/images/flags/' + language.code + '.svg')"
                        height="16"
                        width="16"
                        class="mr-1"
                      />
                      <span class="d-none d-sm-inline">{{ language.title }}</span>
                    </template>
                    <b-row>
                      <b-col
                        cols="24"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'title.' + language.code"
                        >
                          <b-form-input
                            :id="'title.' + language.code"
                            v-model="item.title[language.code]"
                            :state="errors && errors['title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                            {{ errors['title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('form.status.label')"
                      label-for="status"
                      :state="errors && errors.status ? false : null"
                    >
                      <v-select
                        id="status"
                        v-model="item.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="status"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.status">
                        {{ errors.status[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Action Buttons -->
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="saveList"
                >
                  {{ $t('general.save') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :to="{ name: 'navigations-index' }"
                >
                  {{ $t('general.cancel') }}
                </b-button>
                <b-button
                  v-if="$ability.can('store', 'navigations')"
                  variant="warning"
                  :to="{ name: 'navigation-items-store', params: { navigation_id: item.id } }"
                >
                  <span class="text-nowrap">{{ $t('general.add') }}</span>
                </b-button>
              </b-form>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-card>
              <div>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <div class="halo-tree">
                      <draggable
                        v-model="items"
                        group="tree"
                      >
                        <div
                          v-for="level1 in items"
                          :key="level1.id"
                          class="li"
                        >
                          <div class="tree-node-el">
                            <div>
                              <button
                                class="btn btn-primary btn-sm"
                                @click="level1.expanded = !level1.expanded"
                              >
                                <feather-icon
                                  :icon="!level1.expanded ? 'Maximize2Icon' : 'Minimize2Icon'"
                                  size="14"
                                />
                              </button>
                              <span>{{ level1.title }}</span>
                            </div>
                            <div>
                              <router-link
                                :to="{ name: 'navigation-items-update', params: { id: level1.id, navigation_id: item.id } }"
                                class="btn btn-primary btn-sm"
                              >
                                <feather-icon
                                  icon="EditIcon"
                                  size="14"
                                  class-name="mr-50"
                                />
                              </router-link>
                              <button
                                class="btn btn-danger btn-sm"
                                @click="destroy(level1.id)"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  size="14"
                                  class-name="mr-50"
                                />
                              </button>
                            </div>
                          </div>

                          <div
                            v-if="level1.children"
                            class="halo-tree"
                            :class="!level1.expanded ? 'hidden' : ''"
                          >
                            <draggable
                              v-model="level1.children"
                              group="tree"
                            >
                              <div
                                v-for="level2 in level1.children"
                                :key="level2.id"
                                class="li"
                              >
                                <div class="tree-node-el">
                                  <span>{{ level2.title }}</span>
                                  <div>
                                    <router-link
                                      :to="{ name: 'navigation-items-update', params: { id: level2.id, navigation_id: item.id } }"
                                      class="btn btn-primary btn-sm"
                                    >
                                      <feather-icon
                                        icon="EditIcon"
                                        size="14"
                                        class-name="mr-50"
                                      />
                                    </router-link>
                                    <button
                                      class="btn btn-danger btn-sm"
                                      @click="destroy(level2.id)"
                                    >
                                      <feather-icon
                                        icon="Trash2Icon"
                                        size="14"
                                        class-name="mr-50"
                                      />
                                    </button>
                                  </div>
                                </div>

                                <div
                                  v-if="level2.children"
                                  class="halo-tree"
                                >
                                  <draggable
                                    v-model="level2.children"
                                    group="tree"
                                  >
                                    <div
                                      v-for="level3 in level2.children"
                                      :key="level3.id"
                                      class="li"
                                    >
                                      <div class="tree-node-el">
                                        <span>{{ level3.title }}</span>
                                        <div>
                                          <router-link
                                            :to="{ name: 'navigation-items-update', params: { id: level3.id, navigation_id: item.id } }"
                                            class="btn btn-primary btn-sm"
                                          >
                                            <feather-icon
                                              icon="EditIcon"
                                              size="14"
                                              class-name="mr-50"
                                            />
                                          </router-link>
                                          <button
                                            class="btn btn-danger btn-sm"
                                            @click="destroy(level3.id)"
                                          >
                                            <feather-icon
                                              icon="Trash2Icon"
                                              size="14"
                                              class-name="mr-50"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </draggable>
                                </div>
                              </div>
                            </draggable>
                          </div>
                        </div>
                      </draggable>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>

      </div>
    </b-overlay>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BImg,
  BTable,
  BFormInvalidFeedback,
  BOverlay, BMedia, BMediaBody, BMediaAside, BLink, BFormTextarea, BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import StatusMixin from '@/mixins/StatusMixin'
import draggable from 'vuedraggable'
import SweetAlertMixin from '@mixins/SweetAlertMixin'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BFormTextarea,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BImg,
    BFormInvalidFeedback,
    vSelect,
    BOverlay,
    draggable,
    BInputGroupPrepend,
    BInputGroup,
  },
  mixins: [StatusMixin, SweetAlertMixin],
  data() {
    return {
      item: null,
      languages: [],
      searchword: '',
      items: [],
      apiUrl: process.env.VUE_APP_APP_URL,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/administration/navigations/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'navigations-index' })
        }
      })

    if (!Object.keys(this.items).length) {
      await this.$http.get('/api/administration/navigation_items', {
        params: {
          navigation_id: this.item.id,
        },
      })
        .then(response => {
          this.items = response.data.map(item => ({
            ...item,
            expanded: true,
          }))
        })
    }
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/administration/navigation_items/${id}`)
          .then(() => this.fetchData())
          .catch(error => {
            this.fetchData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
    fetchData() {
      this.$http.get('/api/administration/navigation_items', {
        params: {
          navigation_id: this.item.id,
        },
      })
        .then(response => {
          this.items = response.data.map(item => ({
            ...item,
            expanded: true,
          }))
        })
    },
    onSubmit() {
      const data = {
        status: this.item.status,
        title: this.item.title,
      }

      this.$http.put(`/api/administration/navigations/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'navigations-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        status: item.status,
        title: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
      })

      return data
    },
    saveList() {
      const data = { items: this.mapCat(this.items) }
      this.$http.post('/api/administration/sort_navigation_items', data)
        .then(() => {
          this.onSubmit()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    mapCat(items, parent_id = null) {
      return items.map(item => ({
        id: item.id,
        parent_id,
        children: this.mapCat(item.children, item.id),
      }))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/tree.scss";
</style>
