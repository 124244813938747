import Vue from 'vue'
import _ from 'lodash'

Vue.filter('transformForVSelect', (data, value, label, entity = null) => data.map(item => ({
  label: entity ? item[entity][label] : item[label],
  value: entity ? item[entity][value] : item[value],
})))

Vue.filter('transformForBSelect', (data, value, text) => data.map(item => ({
  text: item[text],
  value: item[value],
})))

Vue.filter('preparePermissions', data => {
  const permissions = {}

  _.each(data, permission => {
    _.each(permission, (value, key) => {
      if (key !== 'module') {
        permissions[`${permission.module}-${key}`] = value
      }
    })
  })

  return permissions
})

Vue.filter('full_freeze', data => {
  if (!Object.keys(data).length) {
    return data
  }

  return _.reduce(data, (result, value, key) => {
    result[key] = Object.freeze(value)
    return result
  }, {})
})

Vue.filter('singleMedia', file => {
  if (file && file.media && file.media.id) {
    file.media.type = file.type

    return file.media
  }

  return null
})

Vue.filter('multipleMedia', data => {
  const files = []

  if (!data) {
    return []
  }

  _.forEach(data, file => {
    const media = file.media && file.media.id ? file.media : null
    if (media) {
      media.type = file.type

      files.push(media)
    }
  })

  return files
})

Vue.filter('mediaUrl', (item, field, size, locale) => {
  let image = {}
  if (locale) {
    image = item[field][locale]
  } else {
    image = item[field]
  }

  if (!image) {
    return ''
  }

  if (Object.prototype.hasOwnProperty.call(image, 'media')) {
    return image.media && image.media.url ? image.media.url : ''
  }

  return image.url ? image.url : ''
})

Vue.filter('transliterate', word => {
  const a = {
    '(': '_', ')': '_', ',': '_', '.': '_', ' ': '_', Ё: 'YO', Й: 'I', Ц: 'TS', У: 'U', К: 'K', Е: 'E', Н: 'N', Г: 'G', Ш: 'SH', Щ: 'SCH', З: 'Z', Х: 'H', Ъ: '', ё: 'yo', й: 'i', ц: 'ts', у: 'u', к: 'k', е: 'e', н: 'n', г: 'g', ш: 'sh', щ: 'sch', з: 'z', х: 'h', ъ: '', Ф: 'F', Ы: 'I', В: 'V', А: 'a', П: 'P', Р: 'R', О: 'O', Л: 'L', Д: 'D', Ж: 'ZH', Э: 'E', ф: 'f', ы: 'i', в: 'v', а: 'a', п: 'p', р: 'r', о: 'o', л: 'l', д: 'd', ж: 'zh', э: 'e', Я: 'Ya', Ч: 'CH', С: 'S', М: 'M', И: 'I', Т: 'T', Ь: '', Б: 'B', Ю: 'YU', я: 'ya', ч: 'ch', с: 's', м: 'm', и: 'i', т: 't', ь: '', б: 'b', ю: 'yu',
  }
  return word.split('').map(char => a[char] || char).join('').toLowerCase()
})
