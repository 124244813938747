export default [
  {
    header: 'administration.navigation.feeds_pages',
  },
  {
    title: 'administration.navigation.feeds',
    icon: 'PaperclipIcon',
    children: [
      {
        title: 'Export',
        route: 'feeds-index',
        action: 'index',
        resource: 'feeds',
      },
      {
        title: 'Import',
        route: 'imports-index',
        action: 'index',
        resource: 'feeds',
      },
    ],
  },
]
