<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('administration.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label for="category">{{ $t('administration.table.fields.category') }}</label>
          <treeselect
            id="category"
            :value="categoryFilter"
            :multiple="false"
            :options="categoryOptions"
            :normalizer="normalizer"
            class="w-100"
            @input="(value) => $emit('update:categoryFilter', value)"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label for="indexing_status">{{ $t('administration.table.fields.need_manage') }}</label>
          <template v-if="needManageFilter">
            <b-input-group>
              <b-input-group-prepend :class="`w-75`">
                <v-select
                  id="need_manage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :value="needManageFilter"
                  :options="manageOptions"
                  class="w-100"
                  :reduce="val => val.value"
                  @input="(val) => $emit('update:needManageFilter', val)"
                />
              </b-input-group-prepend>
              <b-input-group-append :class="`w-25`">
                <b-button
                  variant="primary"
                  :class="`w-100`"
                  :style="`padding: 0;`"
                  @click="download"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="18"
                    class="align-middle"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </template>
          <template v-else>
            <v-select
              id="need_manage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="needManageFilter"
              :options="manageOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:needManageFilter', val)"
            />
          </template>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="status">{{ $t('administration.table.fields.status') }}</label>
          <v-select
            id="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="indexing_status">{{ $t('administration.table.fields.indexing_status') }}</label>
          <v-select
            id="indexing_status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="indexingStatusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:indexingStatusFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="indexing_status">{{ $t('form.bundle.label') }}</label>
          <v-select
            id="bundle_status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="bundleStatusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:bundleStatusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BInputGroup, BInputGroupAppend, BButton, BInputGroupPrepend,
} from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'
import vSelect from 'vue-select'
import StatusMixin from '@/mixins/StatusMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    Treeselect,
    vSelect,
  },
  mixins: [CategoryMixin, StatusMixin, SweetAlertMixin],
  props: {
    categoryFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    needManageFilter: {
      type: [String, null],
      default: null,
    },
    indexingStatusFilter: {
      type: [String, null],
      default: null,
    },
    bundleStatusFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      categoryOptions: [],
      manageOptions: [
        { label: this.$t('general.need_manage.allneeded'), value: 'allneeded' },
        { label: this.$t('general.need_manage.images'), value: 'images' },
        { label: this.$t('general.need_manage.categories'), value: 'categories' },
        { label: this.$t('general.need_manage.attributes'), value: 'attributes' },
        { label: this.$t('general.need_manage.brand'), value: 'brand' },
        { label: this.$t('general.need_manage.description'), value: 'description' },
      ],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/administration/categories', {
      params: {
        type: 'product',
        status: 'enabled',
      },
    })
      .then(response => {
        this.categoryOptions = response.data
      })
  },
  methods: {
    download() {
      this.$http.get('/api/administration/need_manage_products', {
        params: {
          need_manage: this.needManageFilter,
        },
        responseType: 'blob',
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'need_manage_products.txt')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
