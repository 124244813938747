<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('administration.products.tabs.data.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="item.title"
                      :state="errors && errors.title ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.title">
                      {{ errors.title[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.stock.label')"
                    label-for="stock_id"
                    :state="errors && errors.stock_id ? false : null"
                  >
                    <v-select
                      id="stock_id"
                      v-model="item.stock_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stocksOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="stock_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.stock_id">
                      {{ errors.stock_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typesOptions"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.quantity.label')"
                    label-for="quantity"
                  >
                    <b-form-input
                      id="quantity"
                      v-model="item.quantity"
                      :state="errors && errors.quantity ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.quantity">
                      {{ errors.quantity[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row v-if="item.type === 'url'">
                <b-col cols="12">
                  <b-form-group
                    :label="$t('form.url.label')"
                    label-for="url"
                  >
                    <b-form-input
                      id="url"
                      v-model="item.url"
                      :state="errors && errors.url ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.url">
                      {{ errors.url[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="item.type === 'email'">
                <b-col cols="12">
                  <b-form-group
                    :label="$t('form.mail_server.label')"
                    label-for="mail_server"
                  >
                    <b-form-input
                      id="mail_server"
                      v-model="item.mail_server"
                      :state="errors && errors.mail_server ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.mail_server">
                      {{ errors.mail_server[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.mail_login.label')"
                    label-for="mail_login"
                  >
                    <b-form-input
                      id="mail_login"
                      v-model="item.mail_login"
                      :state="errors && errors.mail_login ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.mail_login">
                      {{ errors.mail_login[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.mail_passw.label')"
                    label-for="mail_passw"
                  >
                    <b-form-input
                      id="mail_passw"
                      v-model="item.mail_passw"
                      :state="errors && errors.mail_passw ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.mail_passw">
                      {{ errors.mail_passw[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('form.mail_partner_email.label')"
                    label-for="mail_partner_email"
                  >
                    <b-form-input
                      id="mail_partner_email"
                      v-model="item.mail_partner_email"
                      :state="errors && errors.mail_partner_email ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.mail_partner_email">
                      {{ errors.mail_partner_email[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group
                    :label="$t('form.column_product.label')"
                    label-for="column_product"
                  >
                    <b-form-input
                      id="column_product"
                      v-model="item.column_product"
                      :state="errors && errors.column_product ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_product">
                      {{ errors.column_product[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_title.label')"
                    label-for="column_title"
                  >
                    <b-form-input
                      id="column_title"
                      v-model="item.column_title"
                      :state="errors && errors.column_title ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_title">
                      {{ errors.column_title[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_isbn.label')"
                    label-for="column_isbn"
                  >
                    <b-form-input
                      id="column_isbn"
                      v-model="item.column_isbn"
                      :state="errors && errors.column_isbn ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_isbn">
                      {{ errors.column_isbn[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_price.label')"
                    label-for="column_price"
                  >
                    <b-form-input
                      id="column_price"
                      v-model="item.column_price"
                      :state="errors && errors.column_price ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_price">
                      {{ errors.column_price[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_quantity.label')"
                    label-for="column_quantity"
                  >
                    <b-form-input
                      id="column_quantity"
                      v-model="item.column_quantity"
                      :state="errors && errors.column_quantity ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_quantity">
                      {{ errors.column_quantity[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-form-group label="Пропустити якщо виконується одне із правил"/>
              <b-row>
                <b-col cols="2">
                  <b-button
                    variant="primary"
                    block
                    @click="addContinue"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                  </b-button>
                </b-col>
                <b-col cols="10">
                  <div class="repeater-form">
                    <b-row
                      v-for="(cont, index) in item.continue_if"
                      :id="'cont' + index"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group>
                          <v-select
                            v-model="cont.f"
                            :options="ciFieldOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-form-group>
                          <v-select
                            v-model="cont.c"
                            :options="ciCompareOptions"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group>
                          <b-form-input v-model="cont.v"/>
                        </b-form-group>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col
                        md="2"
                        class="mb-50"
                      >
                        <b-button
                          variant="danger"
                          block
                          @click="removeContinue(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <div
                    v-for="ao in attributesOptions"
                    :key="ao.value"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        class="mb-1"
                      >
                        {{ ao.label }}
                      </b-col>
                      <b-col cols="12">
                        <div class="check_wrap mh_200">
                          <b-row class="checks">
                            <b-col
                              v-for="avo in attributeValuesOptions[`attribute_${ao.value}`]"
                              :key="avo.value"
                              cols="3"
                            >
                              <b-form-checkbox
                                v-model="item.attributes"
                                :value="avo.value"
                                class="custom-control-primary"
                              >
                                {{ avo.label }}
                              </b-form-checkbox>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                    <hr>
                  </div>
                  <b-row v-if="errors && errors.attributes">
                    <b-col cols="12">
                      <b-form-invalid-feedback :state="false">
                        {{ errors.attributes[0] }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <br>
              <br>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'imports-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BMedia,
  BMediaBody,
  BMediaAside,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BFormInvalidFeedback,
  BImg,
  BOverlay,
  BFormTextarea,
  BLink,
  BFormCheckbox,
  BFormFile,
} from 'bootstrap-vue'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import StatusMixin from '@/mixins/StatusMixin'
import _ from 'lodash'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BImg,
    BFormTextarea,
    BFormInvalidFeedback,
    vSelect,
    BOverlay,
    BFormCheckbox,
  },
  mixins: [StatusMixin],
  data() {
    return {
      item: {
        status: 'enabled',
        title: null,
        quantity: 1,
        stock_id: 1,
        attributes: [],
        type: 'url',
        url: null,
        mail_server: null,
        mail_login: null,
        mail_passw: null,
        mail_partner_email: null,
        column_product: 'root',
        column_title: 'title',
        column_isbn: null,
        column_price: null,
        column_quantity: null,
        continue_if: [],
      },
      stocksOptions: [],
      typesOptions: ['url', 'email'],
      attributesOptions: [],
      attributeValuesOptions: [],
      ciFieldOptions: [
        { label: 'Ціна', value: 'price' },
        { label: 'Кількість', value: 'quantity' },
      ],
      ciCompareOptions: ['==', '!=', '>=', '<='],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  beforeCreate() {
    this.$http.get('/api/stocks', { params: { mode: 'client', per_page: 100 } })
      .then(response => {
        this.stocksOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })
  },
  created() {
    this.loadDataTab()
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    loadDataTab() {
      if (!Object.keys(this.attributesOptions).length) {
        this.$http.get('/api/attributes', {
          params: {
            sort_by: 'title', sort_desc: false, status: 'enabled', per_page: 200, relations: 'values.translations', query: 'бренд',
          },
        })
          .then(response => {
            this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')

            const vm = this

            _.each(response.data.data, item => {
              vm.attributeValuesOptions[`attribute_${item.id}`] = vm.$options.filters.transformForVSelect(item.values, 'id', 'title')
            })
          })
      }
    },
    addContinue() {
      this.item.continue_if.push({
        f: 'price',
        c: '===',
        v: 0,
      })
    },
    removeContinue(index) {
      this.item.continue_if.splice(index, 1)
    },
    async onSubmit() {
      const data = {
        title: this.item.title,
        status: this.item.status,
        quantity: this.item.quantity,
        type: this.item.type,
        stock_id: this.item.stock_id,
        attributes: this.item.attributes,
        url: this.item.url,
        mail_server: this.item.mail_server,
        mail_login: this.item.mail_login,
        mail_passw: this.item.mail_passw,
        mail_partner_email: this.item.mail_partner_email,
        column_product: this.item.column_product,
        column_title: this.item.column_title,
        column_isbn: this.item.column_isbn,
        column_price: this.item.column_price,
        column_quantity: this.item.column_quantity,
        continue_if: this.item.continue_if,
      }

      this.$http.post('/api/administration/imports', data)
        .then(() => {
          router.replace({ name: 'imports-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.custom-checkbox {
  margin:0 0 15px;
}

.mh_200 {
  max-height: 190px;
  overflow: scroll;
}
</style>
