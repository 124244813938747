export default [
  {
    header: 'administration.navigation.system_pages',
  },
  {
    title: 'administration.navigation.system',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'FileManager',
        route: 'fm',
        action: 'index',
        resource: 'settings',
      },
      {
        title: 'administration.navigation.settings',
        route: 'settings',
        action: 'index',
        resource: 'settings',
      },
      // {
      //   title: 'administration.navigation.languages',
      //   route: 'languages',
      //   action: 'index',
      //   resource: 'languages',
      // },
      {
        title: 'administration.navigation.translations',
        route: 'translations',
        action: 'index',
        resource: 'translations',
      },
      {
        title: 'administration.navigation.shops',
        route: 'shops-index',
        action: 'index',
        resource: 'shops',
      },
      // {
      //   title: 'administration.navigation.payment_methods',
      //   route: 'payment_methods-index',
      //   action: 'index',
      //   resource: 'payment_methods',
      // },
      // {
      //   title: 'administration.navigation.delivery_methods',
      //   route: 'delivery_methods-index',
      //   action: 'index',
      //   resource: 'delivery_methods',
      // },
    ],
  },
]
