<template>
  <b-form class="mt-1">
    <b-row
      v-if="$ability.can('update', 'settings')"
      class="mt-2"
    >
      <b-col>
        <b-form-group
          label="Оновити відділення"
          label-for="cache"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="disabled"
            @click="run"
          >
            {{ $t('general.run') }}
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BFormGroup,
} from 'bootstrap-vue'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BForm,
    BButton,
  },
  mixins: [SweetAlertMixin],
  data() {
    return {
      disabled: true,
    }
  },
  async beforeCreate() {
    await this.$http('/api/nova_poshta').then(response => {
      this.disabled = !response.data.can_sync
    })
  },
  methods: {
    run() {
      this.confirm(() => {
        this.$http.post('/api/nova_poshta')
          .then(() => {
            this.disabled = true
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
          .catch(error => {
            this.disabled = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        text: this.$t('notifications.confirm.run_sync.text'),
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
