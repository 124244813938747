<template>
  <div>
    <b-card>
      <file-manager :settings="settings" />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      settings: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
        baseUrl: `${process.env.VUE_APP_API_URL}/file-manager`,
        windowsConfig: 2,
        showNaw: true,
        lang: this.$i18n.locale,
      },
    }
  },
}
</script>
